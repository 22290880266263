import Vuex from 'vuex'
import Vue from 'vue';
import NaWiekiService from '@/shared/application/nawieki-service-proxy';
import { IGuestModel } from '@/shared/models/guest-model';
import { ITranslationModel } from '@/shared/models/translation-model';
import { IMembershipStatusModel } from '@/shared/models/membership-status-model';

Vue.use(Vuex);


export const defaultDomain: string = "nawieki.pl";
export const availableDomains: string[] = ["nawieki.pl", "slub2025.pl", "slub2026.pl"];
const api: NaWiekiService = new NaWiekiService();

const defaultWebsite: IWebsiteModel = {
    brideName: "",
    bridePhone: "",
    brideEmail: "",
    churchAddress: "",
    churchLatLon: "",
    churchPlaceId: "",
    churchUserName: "",
    galleryPhotosFileIds: [],
    groomName: "",
    groomPhone: "",
    groomEmail: "",
    mainPhotoFileId: "",
    templateId: 2,
    websiteAddress: "",
    domain: defaultDomain,
    weddingDate: "",
    weddingTime: "",
    weddingHallAddress: "",
    weddingHallLatLon: "",
    weddingHallPlaceId: "",
    weddingHallUserName: "",
    welcomeText: "",
    smsRemaining: 0,
    contactPersonName: "",
    contactPersonPhone: "",
    childAge: null,
    teenAge: null,
    selectedDiets: [],
    giftsDescription: null,
    giftsDescriptionEnabled: false,
    giftsListEnabled: false,
    isVaccinatedQuestion: false,
    vaccinationDetailsQuestion: false,
    aftermathPresenceQuestion: false, 
    accommodationNeededQuestion: false,
    preWeddingEventName: "",
    preWeddingEventDate: "",
    preWeddingEventTime: "",
    preWeddingEventAddress: "",
    preWeddingEventLatLon: "",
    preWeddingEventUserName: "",
    postWeddingEventName: "",
    postWeddingEventDate: "",
    postWeddingEventTime: "",
    postWeddingEventAddress: "",
    postWeddingEventLatLon: "",
    postWeddingEventUserName: "",
    bridePhotoFileId: "",
    groomPhotoFileId: ""
};



export const appStore = new Vuex.Store({
    state: {
        website: {} as IWebsiteModel,
        websiteId: "",
        newWebsite: false,
        customValidationMessage: null as string | null,
        gifts: [] as Array<IGiftModel>,
        guests: [] as Array<IGuestModel>,
        translations: [] as Array<ITranslationModel>,
        membership: {} as IMembershipStatusModel
    },
    mutations: {
        setWebsite(state, website: IWebsiteModel) {
            if (!!website && website.brideName != null && website.groomName != null) {
                state.website = website;
            } else {
                state.website = defaultWebsite;
                state.website.brideEmail = website.brideEmail;
                state.website.groomEmail = website.groomEmail;
                state.newWebsite = true;
            }
        },
        setTemplate(state, templateId: number) {
            state.website.templateId = templateId;
        },
        setBrideName(state, brideName: string) {
            state.website.brideName = brideName;
        },
        setGroomName(state, groomName: string) {
            state.website.groomName = groomName;
        },
        setBrideEmail(state, brideEmail: string) {
            state.website.brideEmail = brideEmail;
        },
        setGroomEmail(state, groomEmail: string) {
            state.website.groomEmail = groomEmail;
        },
        setBridePhone(state, bridePhone: string) {
            state.website.bridePhone = bridePhone;
        },
        setGroomPhone(state, groomPhone: string) {
            state.website.groomPhone = groomPhone;
        },
        setWebsiteAddress(state, websiteAddress: string) {
            state.website.websiteAddress = websiteAddress;
        },
        setDomain(state, domain: string) {
            state.website.domain = domain;
        },
        setWeddingDate(state, weddingDate: string) {

            state.website.weddingDate = weddingDate;
        },
        setWeddingTime(state, weddingTime: string) {
            state.website.weddingTime = weddingTime;
            //state.website.weddingTime = weddingTime;
        },
        setWelcomeText(state, welcomeText: string) {
            state.website.welcomeText = welcomeText;
        },
        setMainPhotoFileId(state, id: string) {
            state.website.mainPhotoFileId = id;
        },
        setGalleryPhotosFileIds(state, ids: string[]) {
            state.website.galleryPhotosFileIds = ids;
        },
        setContactPersonName(state, contactPersonName: string) {
            state.website.contactPersonName = contactPersonName;
        },
        setContactPersonPhone(state, contactPersonPhone: string) {
            state.website.contactPersonPhone = contactPersonPhone;
        },
        setChurchAddress(state, address: any) {
            state.website.churchAddress = address.address;
            state.website.churchLatLon = address.latLon;
            state.website.churchPlaceId = address.placeId;
            state.website.churchUserName = address.userName;
        },
        setWeddingHallAddress(state, address: any) {
            state.website.weddingHallAddress = address.address;
            state.website.weddingHallLatLon = address.latLon;
            state.website.weddingHallPlaceId = address.placeId;
            state.website.weddingHallUserName = address.userName;
        },
        setChurchUserName(state, userName: string) {
            state.website.churchUserName = userName;
        },
        setWeddingHallUserName(state, userName: string) {
            state.website.weddingHallUserName = userName;
        },
        setSelectedDiets(state, selectedDiets: number[]) {
            state.website.selectedDiets = selectedDiets;
        },
        setChildAge(state, childAge: number | null) {
            state.website.childAge = childAge;
        },
        setTeenAge(state, teenAge: number | null) {
            state.website.teenAge = teenAge;
        },
        setCustomValidationMessage(state, customValidationMessage: string | null) {
            state.customValidationMessage = customValidationMessage;
        },
        setGuests(state, guests: Array<IGuestModel>) {
            state.guests = guests;
        },
        setGifts(state, gifts: Array<IGiftModel>) {
            state.gifts = gifts;
        },
        setGiftsDescription(state, giftsDescription: string) {
            state.website.giftsDescription = giftsDescription;
        },
        setGiftsDescriptionEnabled(state, giftsDescriptionEnabled: boolean) {
            state.website.giftsDescriptionEnabled = giftsDescriptionEnabled;
        },
        setGiftsListEnabled(state, giftsListEnabled: boolean) {
            state.website.giftsListEnabled = giftsListEnabled;
        },
        setIsVaccinatedQuestion(state, val: boolean) {
            state.website.isVaccinatedQuestion = val;
        },
        setVaccinationDetailsQuestion(state, val: boolean) {
            state.website.vaccinationDetailsQuestion = val;
        },
        setAftermathPresenceQuestion(state, val: boolean) {
            state.website.aftermathPresenceQuestion = val;
        },
        setAccommodationNeededQuestion(state, val: boolean) {
            state.website.accommodationNeededQuestion = val;
        },
        setTranslations(state, translations: Array<ITranslationModel>) {
            state.translations = translations;
        },
        setTranslation(state, translation: ITranslationModel) {
            state.translations = state.translations.filter(x => x.id != translation.id);
            state.translations.push(translation);
        },
        setPreWeddingData(state, data: any) {
            state.website.preWeddingEventName = data.eventName;
            state.website.preWeddingEventDate = data.eventDate;
            state.website.preWeddingEventTime = data.eventTime;
            state.website.preWeddingEventAddress = data.eventAddress;
            state.website.preWeddingEventLatLon = data.eventLatLon;
            state.website.preWeddingEventUserName = data.eventUserName;
        },
        setPostWeddingData(state, data: any) {
            state.website.postWeddingEventName = data.eventName;
            state.website.postWeddingEventDate = data.eventDate;
            state.website.postWeddingEventTime = data.eventTime;
            state.website.postWeddingEventAddress = data.eventAddress;
            state.website.postWeddingEventLatLon = data.eventLatLon;
            state.website.postWeddingEventUserName = data.eventUserName;
        },
        setBridePhotoFileId(state, id: string) {
            state.website.bridePhotoFileId = id;
        },
        setGroomPhotoFileId(state, id: string) {
            state.website.groomPhotoFileId = id;
        },
        setMembershipStatus(state, status: IMembershipStatusModel) {
            state.membership = status;
        },
        setWebsiteId(state, id: string) {
            state.websiteId = id;
        }
    },
    actions: {
        async saveGuestQuestions({ commit, state }, payload: { selectedDiets: number[], childAge: number | null, teenAge: number | null }) {
            commit('setSelectedDiets', payload.selectedDiets);
            commit('setChildAge', payload.childAge);
            commit('setTeenAge', payload.teenAge);
            return await api.post<IWebsiteModel>('/api/website', state.website);
        },
        async saveWebsite({ state }) {
            return await api.post<IWebsiteModel>('/api/website', state.website);
        },
        async loadWebsite({ commit }) {
            return await api.get<any>('/api/website', false).then((response) => {
                commit('setWebsite', response.content);
            });
        },
        async loadWebsiteIfNeeded({commit, dispatch, state}) {
            if(!!state.website.websiteAddress) {
                return;
            } else {
                await dispatch("getMembershipStatus");
                return await api.get<any>('/api/website', false).then((response) => {
                    commit('setWebsite', response.content);
                });
            }
        },
        async loadGifts({ commit }) {
            return await api.get<any>('/api/gift', false).then((response) => {
                commit('setGifts', response.content);
            });
        },
        async loadGuests({ commit }) {
            return await api.get<any>('/api/guest', false).then((response) => {
                commit('setGuests', response.content);
            });
        },
        async loadTranslations({ commit }) {
            return await api.get<any>('/api/translation', false).then((response) => {
                commit('setTranslations', response.content);
            });
        },
        async saveGift({ dispatch }, gift: IGiftModel) {
            await api.post<IGiftModel>('/api/gift', gift);
            await dispatch('loadGifts');
        },
        async deleteGift({ dispatch }, giftId: string) {
            await api.delete('/api/gift/' + giftId);
            await dispatch('loadGifts');
        },
        async saveTranslation({ dispatch }, translation: ITranslationModel) {
            await api.post<ITranslationModel>('/api/translation', translation);
            await dispatch('loadTranslations');
        },
        async deleteTranslation({dispatch}, translationId: string) {
            await api.delete('/api/translation/' + translationId);
            await dispatch('loadTranslations');
        },
        async getPlaceById({dispatch}, placeId: string) {
            return await api.get('/api/location/' + placeId);
        }, 
        async getPlaceByLatLon({dispatch}, payload: {lat: string, lon: string}) {
            return await api.get('/api/location/' + payload.lat + "/" + payload.lon);
        },
        async getMembershipStatus({commit}) {
            return await api.get('/api/membership/status').then((response) => {
                commit('setMembershipStatus', response.content);
            });
        }, 
        async getWebsiteId({commit}) {
            return await api.get('/api/website/id').then((response) => {
                commit('setWebsiteId', response.content);
            });
        },
    },
    getters: {
        fullWebsiteAddress: (state) => () => {
            if(state.website.websiteAddress && !state.website.domain) {
                return state.website.websiteAddress;
            } else if(!state.website.websiteAddress && !state.website.domain) {
                return "";
            }
            return state.website.websiteAddress + "." + state.website.domain;
        }
    }
    
})
